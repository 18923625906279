import axios from 'axios';

//importar funciones helpers
import {TokenValido } from '../src/helpers.js'


const LARAVEL_PROTOCOL = process.env.REACT_APP_LARAVEL_PROTOCOL
const LARAVEL_HOST= process.env.REACT_APP_LARAVEL_HOST 
const LARAVEL_PORT= process.env.REACT_APP_LARAVEL_PORT
const LARAVEL_HOST_SOCKET= process.env.REACT_APP_LARAVEL_HOST_SOCKET
const LARAVEL_PORT_SOCKET= process.env.REACT_APP_SOCKET_PUERTO

 

let token = TokenValido()

let config = {"Authorization" : `Bearer ${token}` };
  
//comentario
// Crea una instacia de axios con url base 
export const laravelAPI = axios.create({
    baseURL: `${LARAVEL_PROTOCOL}://${LARAVEL_HOST}:${LARAVEL_PORT}/api/mmtrack/`,
    //timeout: 15000,
    headers : config
});
 
export const laravelReport = `${LARAVEL_PROTOCOL}://${LARAVEL_HOST}:${LARAVEL_PORT}/api/mmtrack/`

//export const DIRECCION_SOCKET = `${LARAVEL_PROTOCOL}://${LARAVEL_HOST_SOCKET}:${LARAVEL_PORT_SOCKET}`
export const DIRECCION_SOCKET = `http://${LARAVEL_HOST_SOCKET}:${LARAVEL_PORT_SOCKET}`